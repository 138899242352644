import React from 'react'
import './footer.css'


export default function footer() {
    return (
        <footer>
            Search Powered by: SeatGeekAPI
        </footer>
    )
}